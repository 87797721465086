import React, { useState } from 'react';
import InvestmentGraph from './InvestmentGraph';
import Ellipses from './Ellipses'; // Import the Ellipses component
import ExitBreakdown from './ExitBreakdown';
import BreakevenAnalysis from './BreakevenAnalysis';
import CapTable from './CapTable';
import './Result.css'; // Import your CSS file

const Result = ({ data, submittedData }) => {
    const [breakevenValues, setBreakevenValues] = useState({}); // To store breakeven values for each investor
    const [loadingInvestor, setLoadingInvestor] = useState(null); // Track the loading state for each investor
    const [isFounderLoading, setIsFounderLoading] = useState(false); // Track if founder's partial value is loading

    if (!data || !data.investment_structures) {
        return <div>No data to display.</div>;
    }

    console.log("THE DATA IS: " + JSON.stringify(data));
    console.log("THE SUBMITTED DATA IS: " + JSON.stringify(submittedData));

    // Extract relevant information for each investor and founder
    const investorInfo = data.investment_structures
        .map(structure => ({
            name: structure.name,
            partialValue: structure.partial_value,
            lpCost: structure.lp_cost,
            lpValuation: structure.lp_valuation,
            gpValuation: structure.gp_valuation,
            breakevenValuation: structure.breakeven_valuation || null,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));  // Sorting based on the 'name' property


    const founderInfo = {
        partialValue: data.founder_partial_value,
    };

    const gpPercentages = submittedData.params.reduce((acc, structure, index) => {
        const seriesName = `Series ${String.fromCharCode(65 + index)}`; // Creates Series A, Series B, etc.
        acc[seriesName] = structure.gp_percent;
        return acc;
    }, {});

    // Function to handle breakeven button click
    const handleBreakevenClick = (investorName) => {
        console.log(`Fetching breakeven valuation for ${investorName}`);

        setLoadingInvestor(investorName); // Set loading state for the specific investor
        setIsFounderLoading(true); // Set loading state for founder partial value

        // Trigger reCAPTCHA
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit_breakeven' }).then((token) => {

                // Replace with your API URL
                const apiUrl = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
                    ? process.env.REACT_APP_API_URL_LOCAL + '/api/breakeven-multiple-series'
                    : 'https://' + window.location.hostname + '/api/breakeven-multiple-series';

                // Remove recaptcha_token from submittedData if it exists
                const { recaptcha_token, ...restOfSubmittedData } = submittedData;

                // Include the investorName and the submittedData in the request body
                const requestData = {
                    recaptcha_token: token,
                    breakeven_series: investorName,
                    ...restOfSubmittedData, // Spread the submittedData to include all form data
                };

                fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestData),
                })
                .then(response => response.json())
                .then(data => {
                    // Update the state with the breakeven valuation for the investor
                    setBreakevenValues((prevValues) => ({
                        ...prevValues,
                        [investorName]: data.breakeven_valuation
                    }));
                })
                .catch(error => {
                    console.error('Error fetching breakeven valuation:', error);
                })
                .finally(() => {
                    setLoadingInvestor(null); // Clear loading state after data is fetched
                    setIsFounderLoading(false); // Clear loading state for founder partial value
                });
            });
          });
    };

    return (
        <div>
            <InvestmentGraph data={data} />
            <div className="investment-details">                
                {/* Make the table container scrollable */}
                <div className="table-scroll">
                    <table className="investment-table">
                        <thead>
                            <tr>
                                <th>Investor</th>
                                <th>Partial Value</th>
                                <th>LP Cost</th>
                                <th>LP Valuation</th>
                                <th>GP Valuation</th>
                                <th>Breakeven Valuation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {investorInfo.map((info, index) => (
                                <tr key={index}>
                                    <td>{info.name}</td>
                                    <td>${info.partialValue.toFixed(2)}M</td>
                                    <td>${info.lpCost.toFixed(2)}M</td>
                                    <td>${info.lpValuation.toFixed(2)}M</td>
                                    <td>${info.gpValuation.toFixed(2)}M</td>
                                    <td>
                                        {info.breakevenValuation ? (
                                            <span>${info.breakevenValuation.toFixed(2)}M</span>
                                        ) : breakevenValues[info.name] ? (
                                            <span>${breakevenValues[info.name].toFixed(2)}M</span>
                                        ) : loadingInvestor === info.name ? (
                                            <Ellipses />
                                        ) : (
                                            <button onClick={() => handleBreakevenClick(info.name)}>
                                                Calculate
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Apply the new class for proper spacing */}
                <p className="founder-value">Founder Partial Value: ${founderInfo.partialValue.toFixed(2)}M</p>
            </div>
            {/* Add the new ExitBreakdown component */}
            <CapTable data={data} founderShares={submittedData.founder_shares} />
            <ExitBreakdown data={data} gpPercentages={gpPercentages} />
            <BreakevenAnalysis data={data} submittedData={submittedData} />
        </div>
    );
};

export default Result;
