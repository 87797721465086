import React from "react";
import "./CapTable.css";

const CapTable = ({ data, founderShares }) => {
  const sortedSeries = data.investment_structures.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <div className="cap-table-container">
      <h3>Cap Table</h3>
      <table className="cap-table">
        <thead>
          <tr>
            <th rowSpan="2">Series</th>
            <th colSpan="2">Original Terms</th>
            <th colSpan="2">Post Dilutive Effects</th>
          </tr>
          <tr>
            <th>Shares</th>
            <th>Price/Share</th>
            <th>Shares</th>
            <th>Price/Share</th>
          </tr>
        </thead>
        <tbody>
          {sortedSeries.map((series, index) => (
            <tr key={index}>
              <td>{series.name}</td>
              <td>{series.original_shares.toFixed(2)}M</td>
              <td>${series.original_price_per_share.toFixed(2)}</td>
              <td>{series.new_shares.toFixed(2)}M</td>
              <td>${series.new_price_per_share.toFixed(2)}</td>
            </tr>
          ))}
          <tr>
            <td>Founder</td>
            <td>{founderShares}M</td>
            <td>--</td>
            <td>{founderShares}M</td>
            <td>--</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CapTable;
