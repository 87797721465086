import React, { useState } from "react";
import './TableRow.css';

const OwnershipTableRow = ({ label, values, onChange, tooltip }) => {
  // State to track the input mode (shares or percentage) for each row
  const [inputModes, setInputModes] = useState(values.map(() => null)); // Default to no selection

  // Handles input changes based on the current input mode (shares or percentage)
  const handleInputChange = (index, value) => {
    const parsedValue = parseFloat(value);

    if (value === "") {
      // Reset both fields if the input is empty
      onChange(index, "vc_shares", null);
      onChange(index, "percentage_ownership", null);
      return;
    }

    if (isNaN(parsedValue)) {
      return; // Skip invalid input
    }

    if (inputModes[index] === "shares") {
      // Update vc_shares when in "shares" mode
      onChange(index, "vc_shares", parsedValue);
      onChange(index, "percentage_ownership", null);
    } else if (inputModes[index] === "percentage") {
      // Update percentage_ownership when in "percentage" mode
      onChange(index, "percentage_ownership", parsedValue);
      onChange(index, "vc_shares", null);
    }
  };

  // Handle the mode toggle (shares or percentage) for each row
  const handleModeChange = (index, mode) => {
    // Reset both fields when the mode changes
    onChange(index, "vc_shares", null);
    onChange(index, "percentage_ownership", null);

    // Update the input mode for the specific row
    const updatedModes = [...inputModes];
    updatedModes[index] = mode; // Update the mode for the specific row
    setInputModes(updatedModes); // Set the updated mode array
  };

  return (
    <tr className="table-row">
      <td className="tooltip-container">
        {label}
        {tooltip && <span className="tooltip-text">{tooltip}</span>}
      </td>
      {values.map((value, index) => (
        <td key={index}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <select
              value={inputModes[index] || ""}
              onChange={(e) => handleModeChange(index, e.target.value)} // Update the mode for the current row
              style={{ marginRight: "8px" }}
              required
            >
              <option value="" disabled>
                Select Mode
              </option>
              <option value="shares">Shares (M)</option>
              <option value="percentage">Ownership (%)</option>
            </select>
            <input
              type="number"
              value={
                inputModes[index] === "shares"
                  ? value.vc_shares || ""
                  : inputModes[index] === "percentage"
                  ? value.percentage_ownership || ""
                  : ""
              }
              onChange={(e) => handleInputChange(index, e.target.value)} // Handle the value change
              step="0.01"
              required
              disabled={!inputModes[index]} // Disable input until mode is selected
              style={{ width: "100%" }}
            />
          </div>
        </td>
      ))}
    </tr>
  );
};

export default OwnershipTableRow;