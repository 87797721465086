import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import Ellipses from './Ellipses';
import BlackScholesCalculator from './BlackScholesCalculator';
import MultipleSeriesCalculator from './MultipleSeriesCalculator';
import IssueReport from './IssueReport';
import './App.css';

// Your Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
initializeApp(firebaseConfig);

function App() {
    const [activeComponent, setActiveComponent] = useState('OptionCalculator');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [riskFreeRate, setRiskFreeRate] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [user, setUser] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);
    const [error, setError] = useState(null);
    const [showLogout, setShowLogout] = useState(false);

    // Fetch risk-free rate
    useEffect(() => {
        const fetchRiskFreeRate = async () => {
            const apiUrl =
                window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
                    ? process.env.REACT_APP_API_URL_LOCAL + '/api/risk-free-rate'
                    : 'https://' + window.location.hostname + '/api/risk-free-rate';

            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), 5000); // Timeout of 5 seconds

            try {
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                    signal: controller.signal,
                });

                clearTimeout(timeoutId);

                if (!response.ok) throw new Error('Network response was not ok ' + response.statusText);

                const result = await response.json();
                setRiskFreeRate(result.risk_free_rate);
                setIsLoaded(true);
            } catch (error) {
                console.error('Error fetching risk-free rate:', error);
                setRiskFreeRate(0.02); // Default rate
                setIsLoaded(true);
            }
        };

        fetchRiskFreeRate();
    }, []);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setIsAuthenticated(true);
                setShowLogout(false);
                setUser(currentUser);
            } else {
                setIsAuthenticated(false);
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const handleGoogleLogin = async () => {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();

        try {
            await signInWithPopup(auth, provider);
        } catch (error) {
            console.error('Error signing in with Google: ', error);
            setError('Google login failed. Please try again.');
        }
    };

    const handleEmailLogin = async () => {
        const auth = getAuth();

        try {
            if (isSignUp) {
                await createUserWithEmailAndPassword(auth, email, password);
            } else {
                await signInWithEmailAndPassword(auth, email, password);
            }
            setError(null);
        } catch (error) {
            console.error('Error with email/password login: ', error);
            setError(error.message);
        }
    };

    const handleLogout = async () => {
        const auth = getAuth();
        try {
            await signOut(auth);
            setIsAuthenticated(false);
            setUser(null);
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const renderComponent = () => {
        switch (activeComponent) {
            case 'OptionCalculator':
                return <BlackScholesCalculator riskFreeRate={riskFreeRate} />;
            case 'MultipleSeriesCalculator':
                return <MultipleSeriesCalculator riskFreeRate={riskFreeRate} />;
            case 'IssueReport':
                return <IssueReport />;
            default:
                return <BlackScholesCalculator riskFreeRate={riskFreeRate} />;
        }
    };

    if (!isAuthenticated) {
        return (
            <div className="login-page">
                <div className="login-card">
                    <h1>{isSignUp ? 'Create Your Account' : 'Welcome Back'}</h1>
                    <p>{isSignUp ? 'Sign up to access your dashboard' : 'Log in to continue'}</p>
                    <div className="input-group">
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <button className="cta-button" onClick={handleEmailLogin}>
                        {isSignUp ? 'Sign Up' : 'Log In'}
                    </button>
                    <button className="link-button" onClick={() => setIsSignUp(!isSignUp)}>
                        {isSignUp ? 'Already have an account? Log In' : 'Don’t have an account? Sign Up'}
                    </button>
                    <div className="separator">
                        <span>OR</span>
                    </div>
                    <button className="google-login-button" onClick={handleGoogleLogin}>
                        <img src="google-icon.svg" alt="Google Icon" /> Sign in with Google
                    </button>
                </div>
            </div>
        );
    }

    const profileInitial = user?.name
        ? user.name[0].toUpperCase()
        : user?.email
        ? user.email[0].toUpperCase()
        : "?";

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '50px' }}>
            <Helmet>
                <title>Term Sheet Genie - Decision Tool for Venture Capitalists and Founders</title>
            </Helmet>
            <div className="profile-menu">
                <div
                    className="profile-button"
                    onClick={handleLogout}
                    aria-label="Profile Menu"
                    onMouseEnter={() => setShowLogout(true)}
                    onMouseLeave={() => setShowLogout(false)}
                >
                    <div className="profile-icon">
                        {!showLogout && (
                            <span className="profile-initials">{profileInitial}</span>
                        )}
                        {showLogout && (
                            <img src="red-x-icon.svg" alt="Logout" className="logout-icon" />
                        )}
                    </div>
                </div>
            </div>
            <nav>
                <ul>
                    <li
                        className={activeComponent === 'OptionCalculator' ? 'bold' : ''}
                        onClick={() => setActiveComponent('OptionCalculator')}
                    >
                        Option Calculator
                    </li>
                    <li
                        className={activeComponent === 'MultipleSeriesCalculator' ? 'bold' : ''}
                        onClick={() => setActiveComponent('MultipleSeriesCalculator')}
                    >
                        Series Calculator
                    </li>
                    <li
                        className={activeComponent === 'IssueReport' ? 'bold' : ''}
                        onClick={() => setActiveComponent('IssueReport')}
                    >
                        Report an Issue
                    </li>
                </ul>
            </nav>
            <div className="component-container">
                {isLoaded ? renderComponent() : <Ellipses />}
            </div>
            <footer
                style={{
                    marginTop: '50px',
                    padding: '20px',
                    textAlign: 'left',
                    fontSize: '12px',
                    color: 'white',
                    maxWidth: '800px',
                    margin: '0 auto',
                    textAlign: 'justify',
                }}
            >
                <p>
                    All information provided on this website is intended solely as a tool for assisting venture capitalists (VCs) and founders with decision-making processes. The content, analysis, and any recommendations offered by this tool are for informational purposes only and should not be considered as financial, legal, or investment advice. The tool is designed to assist VCs and founders in evaluating their deals but does not make decisions on behalf of the user. Users are responsible for their own investment decisions and should consult with qualified professional advisors, including financial, legal, and tax experts, prior to making any investment decisions. The site does not take responsibility for the correctness or completeness of the information provided and is not liable for any losses, damages, or missed opportunities resulting from the use of this tool. By using this website, users acknowledge that the data input into the system may be stored in our database tables for processing purposes. However, no data input should be construed as an offer or solicitation to purchase, sell, or make any investment. The tool does not recommend or promote the purchase or sale of any specific investment, product, or service. The content, trademarks, logos, and other proprietary information on this site are the property of TermSheetGenie and are protected by applicable intellectual property laws. Unauthorized use of this content is prohibited. This site may contain links to third-party websites. We do not control, endorse, or assume any responsibility for the content or services provided by third-party websites. Accessing third-party sites is done at your own risk. If any provision of this disclaimer is deemed invalid or unenforceable, the remaining provisions will continue in full force and effect.
                </p>
            </footer>
        </div>
    );
}

export default App;