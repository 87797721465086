import React, { useState } from 'react';
import ToggleSwitch from './ToggleSwitch';
import './BlackScholesCalculator.css';

const BlackScholesCalculator = ({ riskFreeRate }) => {
    const initialFormState = {
        call_type: 'random_expiration',
        stock_price: '',
        strike_price: '',
        volatility: '',
        risk_free_rate: riskFreeRate || 0,
        holding_period: '',
        time_volatility: ''
    };

    const [formData, setFormData] = useState(initialFormState);
    const [result, setResult] = useState('');
    const [resultStatus, setResultStatus] = useState('');
    const [mode, setMode] = useState('Yes'); // Initialize `mode` and `setMode` here

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleReset = () => {
        setFormData(initialFormState);
        setResult('');
        setResultStatus('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const {
            call_type,
            stock_price,
            strike_price,
            volatility,
            risk_free_rate,
            holding_period,
            time_volatility
        } = formData;

        const stockPrice = Number(stock_price);
        const strikePrice = Number(strike_price);
        const volatilityValue = Number(volatility) / 100;
        const formRiskFreeRate = Number(risk_free_rate) / 100;
        const holdingPeriod = Number(holding_period);
        const timeVolatility = isVanillaCallOption(call_type)
            ? 0
            : (mapToggleToMode(mode) === 'Exponential' ? holdingPeriod : Number(time_volatility));

        if (stockPrice <= 0 || strikePrice <= 0 || volatilityValue <= 0 || holdingPeriod <= 0 || (!isVanillaCallOption(call_type) && timeVolatility < 0)) {
            setResult('Error: All values except Risk Free Rate must be greater than 0, and Time Volatility must be non-negative for Estimated Expiration option types.');
            setResultStatus('error');
            return;
        }

        // Trigger reCAPTCHA
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit_blackscholes' }).then((token) => {
                // Attach the token to the request body
                const data = {
                    call_type,
                    params: {
                        stock_price: stockPrice,
                        strike_price: strikePrice,
                        volatility: volatilityValue,
                        risk_free_rate: formRiskFreeRate,
                        holding_period: holdingPeriod,
                        time_volatility: timeVolatility
                    },
                    recaptcha_token: token // Include the reCAPTCHA token
                };

                const apiUrl = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
                    ? process.env.REACT_APP_API_URL_LOCAL + '/api/black-scholes'
                    : 'https://' + window.location.hostname + '/api/black-scholes';

                fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok ' + response.statusText);
                    }
                    return response.json();
                })
                .then(result => {
                    setResult('Option Price: $' + result.value.toFixed(2));
                    setResultStatus('success');
                })
                .catch(error => {
                    console.error('Error:', error);
                    setResult('Error: ' + error.message);
                    setResultStatus('error');
                });
            });
        });
    };

    const mapToggleToMode = (mode) => {
      if (mode === 'Yes') {
        return 'Gamma';
      }
      return 'Exponential';
    };

    const isVanillaCallOption = (value) => {
        return value === 'regular' || value === 'binary';
    }

    return (
        <div className="calculator-container">
            <h2>Black-Scholes Calculator</h2>
            {!isVanillaCallOption(formData.call_type) && (
                <>
                    <h4>Do You Know the Randomness Around the Holding Period?</h4>
                    <ToggleSwitch
                        name="randomness"
                        options={['Yes', 'No']}
                        selected={mode}
                        onChange={setMode}
                    />
                </>
            )}
            <form id="blackScholesForm" onSubmit={handleSubmit}>
                <div className="tooltip-container">
                    <label htmlFor="call_type">Call Type:</label>
                    <select id="call_type" name="call_type" value={formData.call_type} onChange={handleChange} required>
                        <option value="regular">Regular</option>
                        <option value="binary">Binary</option>
                        <option value="random_expiration">Estimated Expiration</option>
                        <option value="binary_random_expiration">Binary Estimated Expiration</option>
                    </select>
                    <span className="tooltip-text">Regular is the vanilla Black-Scholes call valuation. Binary Calls pay a fixed monetary amount or nothing. Estimated Expiration is the value of the regular or binary call taking into account all exit opportunities.</span>
                </div>

                <div className="tooltip-container">
                    <label>Stock Price ($):</label>
                    <input type="number" id="stock_price" name="stock_price" step="0.01" min="0" value={formData.stock_price} onChange={handleChange} required />
                    <span className="tooltip-text">The current price of the stock</span>
                </div>

                <div className="tooltip-container">
                    <label htmlFor="strike_price">Strike Price ($):</label>
                    <input type="number" id="strike_price" name="strike_price" step="0.01" min="0" value={formData.strike_price} onChange={handleChange} required />
                    <span className="tooltip-text">The price at which the option can be exercised</span>
                </div>

                <div className="tooltip-container">
                    <label htmlFor="volatility">Volatility (%):</label>
                    <input type="number" id="volatility" name="volatility" step="1" min="0" value={formData.volatility} onChange={handleChange} required />
                    <span className="tooltip-text">Expected volatility of the stock price</span>
                </div>

                <div className="tooltip-container">
                    <label htmlFor="risk_free_rate">Risk Free Rate (%):</label>
                    <input type="number" id="risk_free_rate" name="risk_free_rate" step="0.01" value={formData.risk_free_rate} onChange={handleChange} required />
                    <span className="tooltip-text">The rate of return on a risk-free asset. The default value is the current 10 year Treasury Yield</span>
                </div>

                <div className="tooltip-container">
                  <label htmlFor="holding_period">
                    {isVanillaCallOption(formData.call_type)
                      ? 'Time to Exit (Yrs):' 
                      : 'Expected Time to Exit (Yrs):'}
                  </label>
                  <input
                    type="number"
                    id="holding_period"
                    name="holding_period"
                    step="1"
                    min="0"
                    value={formData.holding_period}
                    onChange={handleChange}
                    required
                  />
                  <span className="tooltip-text">
                    {isVanillaCallOption(formData.call_type)
                      ? 'The time duration for holding the option'
                      : 'The expected time duration for holding the option'}
                  </span>
                </div>

                {(!isVanillaCallOption(formData.call_type)) && (
                  <div className={`tooltip-container ${mapToggleToMode(mode) === 'Gamma' ? '' : 'disabled'}`}>
                    <label htmlFor="time_volatility">Volatility Around Holding Period (Yrs):</label>
                    <input
                      type="number"
                      id="time_volatility"
                      name="time_volatility"
                      step="0.01"
                      min="0"
                      value={formData.time_volatility}
                      onChange={handleChange}
                      disabled={mapToggleToMode(mode) !== 'Gamma'} // Disable input in Gamma mode
                      required={mapToggleToMode(mode) === 'Gamma'} // Make required only when active
                    />
                    <span className="tooltip-text">Randomness around the holding period</span>
                  </div>
                )}

                <div className="button-container">
                    <button type="submit">Calculate</button>
                    <button type="button" onClick={handleReset}>Reset</button>
                </div>
            </form>
            <div id="result" className={resultStatus === 'error' ? 'error' : 'success'}>
                {result}
            </div>
        </div>
    );
};

export default BlackScholesCalculator;
